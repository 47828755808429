import { Button } from '@mui/material'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import EditPasswordModal from '../../components/EditPasswordModal/EditPasswordModal'
import { AppContext, IAppContext } from '../../context'

export default function UserPage() {
  const { loggedInUser } = useContext(AppContext) as IAppContext

  const { t } = useTranslation()

  const [showEditPasswordDialog, setShowEditPasswordDialog] = useState(false)

  return (
    <div>
      <h1>User Panel - {t('WIP')}</h1>
      {loggedInUser.username !== '' ? (
        // TODO: a better user panel
        <div>
          <h2>{t('WelcomeUser', { name: loggedInUser.username })}</h2>
          <p>Email: {loggedInUser.email}</p>
          <Button
            variant="contained"
            onClick={() => {
              setShowEditPasswordDialog(true)
            }}
          >
            {t('EditPasswordBtn')}
          </Button>
        </div>
      ) : (
        <h2>{t('LoginPrompt')}</h2>
      )}
      {showEditPasswordDialog && (
        <EditPasswordModal
          onDismiss={() => {
            setShowEditPasswordDialog(false)
          }}
          onPasswordSaved={() => {
            setShowEditPasswordDialog(false)
          }}
        />
      )}
    </div>
  )
}
