import MenuIcon from '@mui/icons-material/Menu'
import {
  AppBar,
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { AppContext, IAppContext } from '../../../context'
import LanguageDropDown from '../LanguageDropDown/LanguageDropDown'
import NavBarLoggedInView from './NavBarLoggedInView'
import NavBarLoggedOutView from './NavBarLoggedOutView'

interface NavBarProps {
  onSignUpClicked: () => void
  // onLoginClicked: () => void
  onLogoutSuccessful: () => void
}

export default function NavBar({
  // onLoginClicked,
  onLogoutSuccessful,
  onSignUpClicked,
}: NavBarProps) {
  const { loggedInUser, setLoggedInUser } = useContext(AppContext) as IAppContext

  const { t } = useTranslation()

  const location = useLocation()
  // TODO: this needs to be changed for tag page
  const isCurrentPath = (path: string) => location.pathname === path

  const isMobile = useMediaQuery('(max-width:600px)')

  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open)
  }

  const drawerContent = (
    <List sx={{ padding: '10px',  }}>
      <ListItem component={RouterLink} to="/post" onClick={toggleDrawer(false)}>
        {t('PostPageTitle')}
      </ListItem>

      <ListItem component={RouterLink} to="/me" onClick={toggleDrawer(false)}>
        Me
      </ListItem>

      {loggedInUser.username !== '' ? (
        <ListItem sx={{justifyContent:'center'}}>
          <NavBarLoggedInView
            isMobile={isMobile}
            onLogoutSuccessful={() => {
              onLogoutSuccessful()
              toggleDrawer(false)
            }}
          />
        </ListItem>
      ) : (
        <NavBarLoggedOutView
          isMobile={isMobile}
          // onLoginClicked={() => {
          //   onLoginClicked()
          //   toggleDrawer(false)
          // }}
          onSignUpClicked={() => {
            onSignUpClicked()
            toggleDrawer(false)
          }}
        />
      )}

      <ListItem>
        <LanguageDropDown />
      </ListItem>
    </List>
  )

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        borderBottom: '1px solid rgb(55,55,55)',
        backdropFilter: 'blur(10px)',
      }}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          variant="h6"
          component={RouterLink}
          to="/"
          style={{
            marginRight: '10px',
            textDecoration: 'none',
            color: 'white',
            /* ,border: '1px solid' */
          }}
        >
          gqriaucue
        </Typography>

        {isMobile && (
          <IconButton edge="end" /* color="inherit" */ onClick={toggleDrawer(true)}>
            <MenuIcon />
          </IconButton>
        )}

        <Drawer open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawerContent}
        </Drawer>

        {!isMobile && (
          <Box display="flex" justifyContent="space-between" width="100%">
            {/* Left-side links */}
            <Box display="flex" alignItems="center">
              <Button
                variant="text"
                component={RouterLink}
                to="/post"
                style={{ fontWeight: isCurrentPath('/post') ? 'bold' : 'normal' }}
              >
                {t('PostPageTitle')}
              </Button>

              <Button
                variant="text"
                component={RouterLink}
                to="/me"
                style={{ fontWeight: isCurrentPath('/me') ? 'bold' : 'normal' }}
              >
                Me
              </Button>
            </Box>

            {/* Right-side user and language */}
            <Box display="flex" alignItems="center">
              {loggedInUser.username !== '' ? (
                <NavBarLoggedInView onLogoutSuccessful={onLogoutSuccessful} />
              ) : (
                <NavBarLoggedOutView
                  // onLoginClicked={onLoginClicked}
                  onSignUpClicked={onSignUpClicked}
                />
              )}

              {/* Language */}
              <LanguageDropDown />
            </Box>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  )
}
