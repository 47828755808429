import { Button, CardActions, Dialog, Grid, IconButton, TextField, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MdDelete, MdDone } from 'react-icons/md'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { AppContext, IAppContext } from '../../../context'
import { UnauthorizedError } from '../../../errors/http_errors'
import { Post } from '../../../models/post.model'
import * as N3k0Api from '../../../service/n3k0_api'
import { PostInput } from '../../../service/n3k0_api'
import { getLastUpdateDateText } from '../../../utils/formatDate'
import { getOGFilename } from '../../../utils/handleFiles'
import { objectsAreDeepEqual } from '../../../utils/objectsAreEqual'
import styleUtils from '../../../utils/util.module.css'
import { Qchip } from '../../Qchip/Qchip'
import { FullScreenImage } from '../FullScreenImage/FullScreenImage'
import styles from './PostCard.module.css'

interface PostCardProps {
  post: Post | null
  open: boolean
  onPrevClicked: () => void
  onNextClicked: () => void
  onClose: () => void
  OnPostEdited: (post: Post) => void
  onDeletePostClicked: (post: Post) => void
}

export interface UpdatePostInput extends PostInput {
  _id: string
  imageURL?: string // for form only
}

export default function PostCard({
  post,
  open,
  onPrevClicked,
  onNextClicked,
  onClose,
  OnPostEdited,
  onDeletePostClicked,
}: PostCardProps) {
  const { loggedInUser, setLoggedInUser } = useContext(AppContext) as IAppContext

  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    setValue,
    unregister,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<UpdatePostInput>({ defaultValues: { ...post } })

  // enables the post being displayed could be updated in real time
  const [currPost, setCurrPost] = useState(post)

  const [editMode, setEditMode] = useState(false)

  const [currTag, setCurrTag] = useState('')
  const [tags, setTags] = useState<string[]>(currPost?.tags || [])

  const [deleteOGImg, setDeleteOGImg] = useState(false)

  // TODO: use the errMsg in a alert component (should we reset it to null somewhere?)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const [showFullscreenImg, setShowFullScreenImg] = useState(false)

  const handleSubmission = async (rawInput: UpdatePostInput) => {
    if (deleteOGImg) {
      rawInput.imageURL = undefined
    } else {
      rawInput.imageURL = currPost?.imageURL
    }
    console.log('raw input:', rawInput)

    // title, desc and tags only
    const oldPostInputSimplified = {
      title: currPost!.title,
      description: currPost!.description,
      tags: currPost!.tags,
      imageURL: currPost!.imageURL,
    }

    const newPostInputSimplified = {
      title: rawInput.title,
      description: rawInput.description,
      tags: rawInput.tags,
      imageURL: rawInput.imageURL,
    }

    // console.log('oldPost:', oldPostInputSimplified)
    // console.log('newPost:', newPostInputSimplified)

    // TODO? Simplify if statements
    if (objectsAreDeepEqual(newPostInputSimplified, oldPostInputSimplified)) {
      if (!rawInput.image) {
        // if nothing was uploaded
        if (rawInput.imageURL === currPost!.imageURL) {
          console.log('No change to this post')
          return
        }
      } else {
        if (currPost!.imageURL === rawInput.image[0].name) {
          console.log('No change to this post')
          return
        }
      }
    }

    const realInput: UpdatePostInput = {
      ...newPostInputSimplified,
      image: rawInput.image,
      _id: rawInput._id,
    }
    console.log('edited post sent:', realInput)
    // send the request and update the post state
    try {
      const response = await N3k0Api.updatePost(rawInput._id, realInput)
      OnPostEdited(response)
      // Update the post props/state???
      setCurrPost(response)
      // turn off the edit mode
      setEditMode(false)
      setDeleteOGImg(false)
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        setErrorMsg(error.message)
      } else {
        alert(error)
      }
      console.error(error)
    }
  }

  const addTag = () => {
    // TODO? add a length limit
    if (currTag && !tags.includes(currTag)) {
      setTags((prevTags) => {
        const updatedTags = [...prevTags, currTag]
        setValue('tags', updatedTags)
        console.log('new Tags:', updatedTags)
        return updatedTags
      })
      setCurrTag('')
    }
  }

  const removeTag = (index: number) => {
    setTags((prevTags) => {
      const updatedTags = prevTags.filter((_, i) => i !== index)
      console.log('after removal:', updatedTags)
      setValue('tags', updatedTags)
      return updatedTags
    })
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const fileSize = file.size / 1024 / 1024
      if (fileSize > 1) {
        setErrorMsg('Max image size: 1MB')
        return
      } else {
        setErrorMsg(null)
      }
      setValue('image', e.target.files)
    }
  }

  useEffect(() => {
    setCurrPost(post)
  }, [post])

  useEffect(() => {
    // set (actual) default values of the form
    if (currPost) {
      const { imageURL, ...defaultPostInput } = currPost
      // console.log('defaultPostInput:', defaultPostInput)
      reset(currPost) // TODO: should it be defaultPostInput?
    }
    setTags(currPost?.tags || []) // tags state are n't maintained by react-hook-form, we need to manually update it
  }, [editMode, currPost])

  useEffect(() => {
    register('tags')
    register('image')

    return () => {
      unregister('tags')
      unregister('image')
    }
  }, [register, unregister])

  // const watchAllFields = watch()
  // console.log(watchAllFields)

  return (
    <>
      <FullScreenImage
        src={currPost?.imageURL}
        title={currPost?.title}
        open={showFullscreenImg}
        onClick={() => {
          setShowFullScreenImg(false)
        }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          overflowY: 'auto' /* , zIndex: 2000 */,
        }}
      />
      <Dialog
        open={open}
        onClose={() => {
          setEditMode(false)
          setDeleteOGImg(false)
          onClose()
        }}
        PaperProps={{
          style: {
            maxWidth: '90vw',
            minWidth: '60vw',
            backgroundColor: 'transparent',
            // boxShadow: 'none', // Remove the default box-shadow
            overflow: 'hidden', // Prevent scrolling
          },
        }}
      >
        <Card
          variant="outlined"
          sx={{
            maxHeight: '80vh',
            display: 'flex' /* , flexDirection: 'column' */,
            margin: '0',
            backgroundColor: 'rgba(0,0,0,.8)',
            overflowY: 'auto',
            // border: '2px solid red',
          }}
          // className={styles.card}
        >
          <Grid
            container // This had some unknown effect on flexbox
            sx={{
              width: '100%',
              // height: '100%',
              display: 'flex',
              flexDirection: 'row', // This won't take effect without 'container'
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              // border: '2px solid red',
            }}
          >
            {/* Image container, adjust maxHeight to reduce clushing */}
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                position: 'relative',
                maxHeight: { xs: '50vh', md: '100%' },
                // border: '2px solid green',
              }}
            >
              <CardMedia
                component="img"
                alt={currPost?.title}
                height="100%"
                image={currPost?.imageURL}
                sx={{ objectFit: 'contain', minWidth: '40vw', cursor: 'zoom-in' }} // To preserve the aspect ratio
                onClick={() => {
                  setShowFullScreenImg(true)
                }}
              />

              <IconButton
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: 0,
                  transform: 'translateY(-50%)',
                  margin: '0 10px',
                  zIndex: 1,
                  backgroundColor: 'rgba(255,255,255,.5)',
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255,.7)',
                  },
                }}
                onClick={() => {
                  onPrevClicked()
                }}
              >
                <ArrowBackIcon />
              </IconButton>

              <IconButton
                sx={{
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  transform: 'translateY(-50%)',
                  margin: '0 10px',
                  zIndex: 1,
                  backgroundColor: 'rgba(255,255,255,.5)',
                  '&:hover': {
                    backgroundColor: 'rgba(255,255,255,.7)',
                  },
                }}
                onClick={() => {
                  onNextClicked()
                }}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Grid>
            {/* Card text content container */}
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                width: '100%',
                // border: '2px solid blue',
              }}
            >
              <form
                className={styles.form}
                encType="multipart/form-data"
                onSubmit={handleSubmit(handleSubmission)}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    overflowY: 'auto',
                    height: '100%',
                    // border: '2px solid green',
                  }}
                >
                  {/* Title||Title textfield */}
                  {/* TODO: add a length limit */}
                  {editMode ? (
                    <TextField
                      label={`Title*`}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      defaultValue={currPost?.title}
                      {...register('title', { required: 'Title is required' })}
                      error={Boolean(errors.title)}
                      helperText={errors.title?.message}
                    />
                  ) : (
                    <Typography gutterBottom variant="h5" component="div">
                      {currPost?.title}
                    </Typography>
                  )}

                  {/* Desc||Desc textfield */}
                  {/* TODO: add a length limit */}
                  {editMode ? (
                    <TextField
                      label="Description"
                      variant="outlined"
                      fullWidth
                      multiline
                      margin="normal"
                      rows={5} // TODO? use auto-sized textfield in mui/base
                      defaultValue={currPost?.description}
                      {...register('description')}
                    />
                  ) : (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className={`${styleUtils.breakLine}`}
                    >
                      {currPost?.description ? currPost.description : t('NoDescMsg')}
                    </Typography>
                  )}

                  {/* Tags||Tags textfield */}
                  {editMode ? (
                    <div>
                      <TextField
                        label="Add a Tag (case sensitive)"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        // value={currTag}
                        onChange={(e) => setCurrTag(e.target.value)}
                        InputProps={{
                          // inputProps: {
                          //   ref: register('tags'),
                          // },
                          endAdornment: (
                            <Button size="small" onClick={addTag}>
                              {t('AddBtn')}
                            </Button>
                          ),
                        }}
                      />

                      {tags && (
                        <div className={styles.chips}>
                          {tags.map((t, index) => (
                            <Qchip key={index} label={t} onDelete={() => removeTag(index)} />
                          ))}
                        </div>
                      )}
                    </div>
                  ) : currPost && currPost.tags ? (
                    <div className={styles.chips}>
                      {currPost.tags.map((tag, index) => {
                        return <Qchip key={index} label={tag} target_blank={true} />
                      })}
                    </div>
                  ) : (
                    <></>
                  )}

                  {/* If a user doesn't upload a new image or doesn't specify that he wants to delete the image, the imageURL keeps the same */}
                  {editMode && (
                    <>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={deleteOGImg}
                            onClick={() => {
                              setDeleteOGImg(!deleteOGImg)
                            }}
                          />
                        }
                        label={t('DelOGImgOnly')}
                      />
                      <Typography variant="caption">
                        {t('CurrentImage')}: {getOGFilename(currPost?.imageURL)}
                      </Typography>
                      {!deleteOGImg && (
                        <TextField
                          type="file"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          InputProps={{
                            inputProps: {
                              ref: register('image'),
                            },
                          }}
                          onChange={handleFileChange}
                          error={Boolean(errorMsg)}
                          helperText={errorMsg}
                        />
                      )}
                    </>
                  )}

                  {/* Timestamp */}
                  {/* {currPost && (
                    <Typography variant="caption">
                      {getLastUpdateDateText(
                        currPost.createdAt,
                        currPost.updatedAt,
                        t('Created'),
                        t('Updated')
                      )}
                    </Typography>
                  )} */}

                  {/* Only show operation btns for admin */}
                  {loggedInUser.role === 'admin' && (
                    <CardActions className={styles.footer}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={editMode}
                            onClick={() => {
                              setEditMode(!editMode)
                            }}
                          />
                        }
                        label={t('Edit')}
                      />

                      <div className={styles.buttonGroup}>
                        <Button
                          variant="outlined"
                          color="error"
                          disabled={isSubmitting || Boolean(errorMsg)}
                          startIcon={<MdDelete />}
                          onClick={() => {
                            onDeletePostClicked(currPost!)
                          }}
                        >
                          {t('Delete')}
                        </Button>

                        {editMode && (
                          <Button
                            type="submit"
                            variant="outlined"
                            color="primary"
                            disabled={isSubmitting || Boolean(errorMsg)}
                            startIcon={<MdDone />}
                          >
                            {t('Finish')}
                          </Button>
                        )}
                      </div>
                    </CardActions>
                  )}
                </CardContent>
              </form>
            </Grid>
          </Grid>
        </Card>
      </Dialog>
    </>
  )
}
