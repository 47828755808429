import { useEffect, useState } from 'react'
import { Qchip } from '../../components/Qchip/Qchip'
import styles from './TagPage.module.css'

interface TagPageTagsViewProps {
  tags: string[]
}

export const TagPageTagsView = ({ tags }: TagPageTagsViewProps) => {
  const [allTags, setAllTags] = useState<string[]>()

  useEffect(() => {
    setAllTags(tags)
  }, [tags])

  return (
    <div className={styles.tags}>
      {allTags?.map((tag, index) => {
        return <Qchip key={index} label={tag} />
      })}
    </div>
  )
}
