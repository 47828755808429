import { Chip } from '@mui/material'
import { Link } from 'react-router-dom'
import React from 'react'

interface QchipProps {
  label: string
  target_blank?: boolean
  [key: string]: any
}

export const Qchip = ({ label, target_blank, ...props }: QchipProps) => {
  const onChipClicked = () => {
    window.open(`/tags?tag=${label}`, '_blank')
  }

  if (target_blank) {
    return (
      <Chip
        label={label}
        onClick={onChipClicked}
        /* component={Link} to={`/tags?tag=${label}`} */ clickable
        {...props}
      />
    )
  } else {
    return <Chip label={label} component={Link} to={`/tags?tag=${label}`} clickable {...props} />
  }
}
