/**
 * Check if 2 non-nested objects are equal
 * @param obj1
 * @param obj2
 * @returns
 */
export function objectsAreShallowEqual(
  obj1: Record<string, any>,
  obj2: Record<string, any>
): boolean {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // Check if both objects have the same number of keys
  if (keys1.length !== keys2.length) {
    return false
  }

  // Check if all keys and values are the same
  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }

  return true
}

/**
 * Check if 2 nested objects are equal
 * @param object1
 * @param object2
 * @returns
 */
export function objectsAreDeepEqual(object1: Record<string, any>, object2: Record<string, any>) {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects = isObject(val1) && isObject(val2)
    if ((areObjects && !objectsAreDeepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false
    }
  }

  return true
}

function isObject(object: unknown) {
  return object != null && typeof object === 'object'
}
