import { Container } from '@mui/material'
import { useContext, useState } from 'react'
import { Route, Routes } from 'react-router'
import './App.css'
import NavBar from './components/layout/NavBar/NavBar'
import { emptyUser } from './constants/emptyUser'
import { AppContext, IAppContext } from './context'
import { HomePage } from './pages/HomePage/HomePage'
// TODO: lazyload
import { LoginPage } from './pages/LoginPage/LoginPage'
// TODO: lazyload
import NotFoundPage from './pages/NotFoundPage/NotFoundPage'
// TODO: lazyload
import PostPage from './pages/PostPage/PostPage'
// TODO: lazyload
import { ProfilePage } from './pages/ProfilePage/ProfilePage'
import { RegisterPage } from './pages/RegisterPage/RegisterPage'
// TODO: lazyload
import { TagPage } from './pages/TagPage/TagPage'
// TODO: lazyload
import UserPage from './pages/UserPage/UserPage'
import './webcomponents/gq-footer'

function App() {
  const { loggedInUser, setLoggedInUser } = useContext(AppContext) as IAppContext

  const [showSignUpModal, setShowSignUpModal] = useState(false)

  return (
    <main className="App">
      <NavBar
        // onLoginClicked={() => {
        //   setShowLoginModal(true)
        // }}
        onSignUpClicked={() => {
          setShowSignUpModal(true)
        }}
        onLogoutSuccessful={() => {
          setLoggedInUser(emptyUser)
        }}
      />

      <Container maxWidth="lg">
        {/* TODO? Banner */}
        <Routes>
          <Route path="/" element={<HomePage />} />

          <Route path="/post" element={<PostPage />} />

          <Route path="/tags" element={<TagPage />} />

          <Route path="/user" element={<UserPage />} />

          <Route path="/me" element={<ProfilePage />} />

          <Route path="/login" element={<LoginPage />} />

          <Route path="/signup" element={<RegisterPage />} />

          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </Container>

      {/* <gq-footer></gq-footer> */}
    </main>
  )
}

export default App
