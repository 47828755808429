import { FormControl, MenuItem, Select } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { LANGUAGES } from '../../../constants/index'
import { AppContext, IAppContext } from '../../../context'
import i18n from '../../../i18n'
import * as N3k0Api from '../../../service/n3k0_api'

export default function LanguageDropDown() {
  const { loggedInUser } = useContext(AppContext) as IAppContext

  const {
    i18n: { language },
    t,
  } = useTranslation()

  const [currDisplayLang, setCurrDisplayLang] = useState(language)

  useEffect(() => {
    setCurrDisplayLang(language)
  }, [language])

  const { control /* , watch */ } = useForm({
    defaultValues: {
      language: currDisplayLang,
    },
  })

  // const selectedLanguage = watch('language')

  const handleLanguageChange = async (
    field: ControllerRenderProps<
      {
        language: string
      },
      'language'
    >,
    selectedKey: string
  ) => {
    // update react-hook-form's value
    field.onChange(selectedKey)
    i18n.changeLanguage(selectedKey)
    setCurrDisplayLang(selectedKey)
    console.log(`Language has been changed to: ${selectedKey}`)
    try {
      await N3k0Api.updatePreference({ ...loggedInUser.preference, lang: selectedKey })
      // TODO: add a badge for successsfully updated user preference
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Controller
      name="language"
      control={control}
      render={({ field }) => (
        <FormControl size="small" sx={{ margin: '0 10px' }}>
          {/* <InputLabel id="language-select-label">Language</InputLabel> */}
          <Select
            labelId="language-select-label"
            value={currDisplayLang}
            onChange={(e) => {
              const selectedKey = e.target.value as string
              handleLanguageChange(field, selectedKey)
            }}
          >
            {LANGUAGES.map(({ code, label }) => (
              <MenuItem value={code} key={code}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  )
}
