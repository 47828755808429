import React from 'react'

export const HomePage = () => {
  return (
    <div>
      <h1>Home Page</h1>
      (Banner?)
    </div>
  )
}
