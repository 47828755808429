import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { emptyUser } from './constants/emptyUser'
import { User } from './models/user.model'
import * as N3k0Api from './service/n3k0_api'

interface Props {
  children: React.ReactNode
}

export interface IAppContext {
  loggedInUser: User
  setLoggedInUser: React.Dispatch<React.SetStateAction<User>>
}

// context can't be null, so we should give it a non-null initial value
export const AppContext = React.createContext<IAppContext>({
  loggedInUser: emptyUser,
  setLoggedInUser: () => {
    console.log('setState function not initialized')
  },
})

export const AppProvider: React.FC<Props> = ({ children }) => {
  const { i18n } = useTranslation()

  const [loggedInUser, setLoggedInUser] = useState<User>(emptyUser)

  async function fetchLoggedInUser() {
    try {
      const user = await N3k0Api.getLoggedInUser()
      setLoggedInUser(user)
      // console.log(user);
    } catch (error) {
      console.error(error)
      // alert(error)
    }
  }
  useEffect(() => {
    fetchLoggedInUser()
  }, [])

  useEffect(() => {
    if (loggedInUser.preference?.lang) {
      i18n.changeLanguage(loggedInUser.preference.lang)
      console.log(`User's lang: ${loggedInUser.preference.lang}`)
    }
  }, [loggedInUser])

  return (
    <AppContext.Provider value={{ loggedInUser, setLoggedInUser }}>{children}</AppContext.Provider>
  )
}
