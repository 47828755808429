export function getOGFilename(originalName: string | undefined) {
  if (!originalName) return ''
  if (originalName === '/images/noimg.png') {
    return 'No image in original post'
  }
  const result = originalName.split('-')
  if (result.length > 1) {
    return result[result.length - 1]
  }
  return originalName
}
