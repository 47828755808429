import { UpdatePostInput } from '../components/layout/PostCard/PostCard'
import { ConflictError, InvalidRequestError, UnauthorizedError } from '../errors/http_errors'
import { Post } from '../models/post.model'
import { User } from '../models/user.model'

/**
 * A wrapper of fetch that properly throws errors
 * @param input
 * @param init
 * @returns
 */
async function fetchData(input: RequestInfo, init?: RequestInit) {
  const res = await fetch(input, init)
  if (res.ok) {
    return res
  } else {
    const errBody = await res.json()
    const errMsg = errBody.error
    switch (res.status) {
      case 400:
        throw new InvalidRequestError(errMsg)
      case 401:
        throw new UnauthorizedError(errMsg)
      case 409:
        throw new ConflictError(errMsg)
      default:
        throw Error(`Request failed with status: ${res.status}, message: ${errMsg}`)
    }
  }
}

// it will send the cookie automatically
// if the backend url is different we need extra config
export async function getLoggedInUser(): Promise<User> {
  const response = await fetchData(`${process.env.API_STRING || ''}/api/users`, { method: 'GET' })
  return response.json()
}

export interface SignUpCredentials {
  username: string
  email: string
  password: string
  role: 'visitor'
}

export async function signUp(credentials: SignUpCredentials): Promise<User> {
  const response = await fetchData(`${process.env.API_STRING || ''}/api/users/signup`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(credentials),
  })
  return response.json()
}

export interface LoginCredentials {
  email: string
  password: string
}

export async function login(credentials: LoginCredentials): Promise<User> {
  const response = await fetchData(`${process.env.API_STRING || ''}/api/users/login`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(credentials),
  })
  return response.json()
}

export async function logout() {
  await fetchData(`${process.env.API_STRING || ''}/api/users/logout`, { method: 'POST' })
}

export interface UpdatePasswordInput {
  oldPassword: string
  newPassword: string
}

export async function updatePassword(input: UpdatePasswordInput) {
  await fetchData(`${process.env.API_STRING || ''}/api/users/my/password`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(input),
  })
}

export interface UpdatePreferenceInput {
  lang?: string
  theme?: string
}

export async function updatePreference(input: UpdatePreferenceInput) {
  await fetchData(`${process.env.API_STRING || ''}/api/users/my/preference`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify(input),
  })
}

/**
 * Fetch all posts, no authentication required
 * @returns
 */
export async function fetchPosts(): Promise<Post[]> {
  const response = await fetchData(`${process.env.API_STRING || ''}/api/posts`, { method: 'GET' })
  return response.json()
}

export async function fetchPostByTag(tag: string): Promise<Post[]> {
  const response = await fetchData(
    `${process.env.API_STRING || ''}/api/posts/tags/tagname/${tag}`,
    { method: 'GET' }
  )
  return response.json()
}

export async function fetchAllTags() {
  const response = await fetchData(`${process.env.API_STRING || ''}/api/posts/tags/all`, {
    method: 'GET',
  })
  return response.json()
}

export interface PostInput {
  title: string
  description?: string
  tags?: string[]
  image: FileList
}

export async function createPost(post: PostInput): Promise<Post> {
  const formData = new FormData()

  // add all attributes in post to FormData
  // NOTE: This only works for obj with 1-level-deep attr
  for (const key in post) {
    if (post.hasOwnProperty(key)) {
      const value = post[key as keyof PostInput]

      if (value instanceof FileList) {
        formData.append(key, value[0])
      } else if (Array.isArray(value)) {
        value.forEach((item) => formData.append(key, item))
      } else if (value !== null && value !== undefined) {
        formData.append(key, value.toString())
      }
    }
  }

  // // Adding each field to the FormData instance
  // formData.append('title', post.title)
  // formData.append('description', post.description || '')

  // // Adding tags, considering it's an array
  // post.tags?.forEach((tag) => {
  //   formData.append('tags', tag)
  // })

  // // Adding the image
  // if (post.image && post.image[0]) {
  //   formData.append('image', post.image[0])
  // }
  const response = await fetchData(`${process.env.API_STRING || ''}/api/posts/update`, {
    method: 'POST',
    // headers: {
    //   'Content-type': 'multipart/form-data',
    // },
    // body: JSON.stringify(post),
    body: formData,
  })
  return response.json()
}

// image could be either a file or a string (if not changed)
export async function updatePost(postId: string, post: UpdatePostInput): Promise<Post> {
  // console.log('post:', post)
  const formData = new FormData()

  // NOTE: This only works for obj with 1-level-deep attr
  for (const key in post) {
    if (post.hasOwnProperty(key)) {
      const value = post[key as keyof UpdatePostInput] // Note the type here
      if (value instanceof FileList) {
        formData.append(key, value[0])
      } else if (Array.isArray(value)) {
        value.forEach((item) => formData.append(key, item))
      } else if (value !== null && value !== undefined) {
        formData.append(key, value.toString())
      }
    }
  }

  const response = await fetchData(`${process.env.API_STRING || ''}/api/posts/update/${postId}`, {
    method: 'POST',
    body: formData,
  })
  return response.json()
}

export async function deletePost(postId: string) {
  await fetchData(`${process.env.API_STRING || ''}/api/posts/update/${postId}`, {
    method: 'DELETE',
  })
}
