import { Backdrop } from '@mui/material'
import React from 'react'

interface FullScreenImageProps {
  src?: string
  title?: string
  open: boolean
  onClick: () => void
  [key: string]: any
}

export const FullScreenImage = ({
  src,
  title,
  open,
  onClick: onClose,
  ...props
}: FullScreenImageProps) => {
  return (
    <Backdrop open={open} onClick={onClose} {...props}>
      <img width="100%" src={src} alt={title} style={{ cursor: 'zoom-out' }} />
    </Backdrop>
  )
}
