import { useEffect, useState, useContext } from 'react'
import { FaPlus } from 'react-icons/fa'
import { Post } from '../../models/post.model'
import * as N3k0Api from '../../service/n3k0_api'
import styleUtils from '../../utils/util.module.css'
import styles from './PostPage.module.css'
import { useTranslation } from 'react-i18next'
import { AppContext, IAppContext } from '../../context'
import PostCard from '../layout/PostCard/PostCard'
import { Modal } from '../Modal/Modal'
import { AddPostDialogMUI } from '../AddPostDialog/AddPostDialogMUI'
import CircularProgress from '@mui/material/CircularProgress'
import { ImageList, ImageListItem, SpeedDial, SpeedDialAction } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'

export default function PostPageLoggedInView() {
  const { loggedInUser } = useContext(AppContext) as IAppContext

  const { i18n, t } = useTranslation()

  // TODO? change the data structure for better indexing performance
  const [posts, setPosts] = useState<Post[]>([])

  const [postsLoading, setPostsLoading] = useState(true)

  const [showLoadingError, setShowLoadingError] = useState(false)

  const [showPostCard, setShowPostCard] = useState(false)

  const [showAddPostDialog, setShowAddPostDialog] = useState(false)

  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [selectedPost, setSelectedPost] = useState<Post | null>(null)

  /* Handle cycling through posts */
  useEffect(() => {
    if (posts.length > 0) {
      setSelectedPost(posts[0])
    }
  }, [posts])

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % posts.length
    setCurrentIndex(nextIndex)
    setSelectedPost(posts[nextIndex])
  }

  const handlePrev = () => {
    const prevIndex = (currentIndex - 1 + posts.length) % posts.length
    setCurrentIndex(prevIndex)
    setSelectedPost(posts[prevIndex])
  }

  // load the posts
  useEffect(() => {
    async function loadPosts() {
      try {
        setShowLoadingError(false) // in case we add a retry button later
        setPostsLoading(true)
        const posts = await N3k0Api.fetchPosts()
        setPosts(posts)
      } catch (error) {
        console.error(error)
        setShowLoadingError(true)
      } finally {
        setPostsLoading(false)
      }
    }
    loadPosts()
  }, [])

  async function deletePost(post: Post) {
    try {
      await N3k0Api.deletePost(post._id)
      setPosts(posts.filter((existingPosts) => existingPosts._id !== post._id))
    } catch (error) {
      console.error(error)
      alert(error)
    }
  }

  // TODO? should we use useMemo?
  const postGrid = (
    <ImageList /* variant="masonry" */ cols={3} gap={8}>
      {/* TODO: Define a default sorting */}
      {posts.map((post) => (
        <ImageListItem key={post._id}>
          <img
            onClick={() => {
              setSelectedPost(post)
              setShowPostCard(true)
            }}
            src={`${post.imageURL}`}
            alt={post.title}
            title={post.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  )

  return (
    <>
      {/* show this button for admin user only */}

      <SpeedDial
        className={`${styles.stickyButton}`}
        ariaLabel="SpeedDial"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        {loggedInUser.role === 'admin' && (
          <SpeedDialAction
            icon={<FaPlus />}
            tooltipTitle={t('AddBtn')}
            tooltipOpen
            onClick={() => setShowAddPostDialog(true)}
          />
        )}
        <SpeedDialAction
          icon={<KeyboardArrowUpIcon />}
          tooltipTitle={'Top'}
          tooltipOpen
          onClick={() => {
            window.scroll({ top: 0, behavior: 'auto' })
          }}
        />
      </SpeedDial>

      {/* show this modal for admin user only */}
      <Modal
        open={showAddPostDialog}
        onClose={() => {
          setShowAddPostDialog(false)
        }}
      >
        <AddPostDialogMUI
          onPostSaved={(newPost) => {
            setShowAddPostDialog(false)
            // Update the post state on this page
            setPosts([newPost, ...posts])
          }}
        />
      </Modal>

      <PostCard
        post={selectedPost}
        open={showPostCard}
        onClose={() => {
          setShowPostCard(false)
        }}
        onPrevClicked={() => {
          //
          handlePrev()
        }}
        onNextClicked={() => {
          handleNext()
        }}
        onDeletePostClicked={(post) => {
          // update the post list state
          if (window.confirm(`Are you sure you want to delete '${post.title}'?`)) {
            deletePost(post)
            // close the postcard
            setShowPostCard(false)
            setSelectedPost(null)
          }
        }}
        OnPostEdited={(updatedPost) => {
          setPosts(
            // replace the post with the updated one
            posts.map((existingPost) =>
              existingPost._id === updatedPost._id ? updatedPost : existingPost
            )
          )
        }}
      />

      {postsLoading && (
        <div className={`${styleUtils.flexCenter} ${styleUtils.width100}`}>
          <CircularProgress />
        </div>
      )}
      {showLoadingError && <h2>{t('GenericErrMsg')}</h2>}

      {!postsLoading && !showLoadingError && (
        <>{posts.length > 0 ? postGrid : <h2>{t('NoAnimeMsg')}</h2>}</>
      )}
    </>
  )
}
