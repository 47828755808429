import React, { useEffect, useState } from 'react'
import { Button, Chip, DialogActions, TextField } from '@mui/material'
import { useForm } from 'react-hook-form'
import { UnauthorizedError } from '../../errors/http_errors'
import * as N3k0Api from '../../service/n3k0_api'
import { PostInput } from '../../service/n3k0_api'
import { Post } from '../../models/post.model'
import { Qchip } from '../Qchip/Qchip'

interface AddPostDialogMUIProps {
  onPostSaved: (post: Post) => void
}

export const AddPostDialogMUI = ({ onPostSaved }: AddPostDialogMUIProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    unregister,
    formState: { errors, isSubmitting },
  } = useForm<PostInput>()

  const [currTag, setCurrTag] = useState('')
  const [tags, setTags] = useState<string[]>([])
  // TODO: use the errMsg in a alert component (should we reset it to null somewhere?)
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const addTag = () => {
    if (currTag) {
      setTags((prevTags) => {
        const updatedTags = [...prevTags, currTag]
        setValue('tags', updatedTags)
        return updatedTags
      })
      setCurrTag('')
    }
  }

  const removeTag = (index: number) => {
    setTags((prevTags) => {
      const updatedTags = prevTags.filter((_, i) => i !== index)
      setValue('tags', updatedTags)
      return updatedTags
    })
  }

  const handleSubmission = async (input: PostInput) => {
    // if(!input.tags) input={tags:[],...input}
    console.log(input)
    try {
      const response = await N3k0Api.createPost(input)
      onPostSaved(response)
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        setErrorMsg(error.message)
      } else {
        alert(error)
      }
      console.error(error)
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const fileSize = file.size / 1024 / 1024 // 文件大小，单位MB
      if (fileSize > 1) {
        setErrorMsg('Max image size: 1MB')
        return
      } else {
        setErrorMsg(null)
      }
      setValue('image', e.target.files)
    }
  }

  useEffect(() => {
    // 在组件加载时注册 input
    register('tags')
    register('image')

    // 在组件卸载时注销 input
    return () => {
      unregister('tags')
      unregister('image')
    }
  }, [register, unregister])

  return (
    <form encType="multipart/form-data" onSubmit={handleSubmit(handleSubmission)}>
      <h2>New Post</h2>

      <TextField
        label={`Title*`}
        variant="outlined"
        fullWidth
        margin="normal"
        // required // This will cause browser's prompt overwrites custom error message
        {...register('title', { required: 'Title is required' })} // it will automatically generate 'name' attr for textfield
        error={Boolean(errors.title)}
        helperText={errors.title?.message}
      />

      <TextField
        label="Description"
        variant="outlined"
        fullWidth
        multiline
        margin="normal"
        rows={5}
        {...register('description')}
      />

      <TextField
        label="Add a Tag (case sensitive)"
        variant="outlined"
        fullWidth
        margin="normal"
        value={currTag}
        onChange={(e) => setCurrTag(e.target.value)}
        InputProps={{
          // inputProps: {
          //   ref: register('tags'),
          // },
          endAdornment: (
            <Button size="small" onClick={addTag}>
              Add
            </Button>
          ),
        }}
      />

      <div>
        {tags.map((t, index) => (
          <Qchip key={index} label={t} onDelete={() => removeTag(index)} style={{ margin: '5px' }} />
        ))}
      </div>

      {/* TODO: an alternative textfield for image url */}
      <TextField
        type="file"
        variant="outlined"
        fullWidth
        margin="normal"
        InputProps={{
          inputProps: {
            ref: register('image'),
          },
        }}
        onChange={handleFileChange}
        error={Boolean(errorMsg)}
        helperText={errorMsg}
      />
      <DialogActions>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isSubmitting || Boolean(errorMsg)}
        >
          上传
        </Button>
      </DialogActions>
    </form>
  )
}
