import { Alert, Button, Form, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { User } from '../../models/user.model'
import { UpdatePasswordInput } from '../../service/n3k0_api'
import TextInputField from '../form/TextInputField'
import * as N3k0Api from '../../service/n3k0_api'
import { useState } from 'react'
import { UnauthorizedError } from '../../errors/http_errors'

interface EditPasswordModalProps {
  onDismiss: () => void
  onPasswordSaved: () => void
}

export default function EditPasswordModal({ onDismiss, onPasswordSaved }: EditPasswordModalProps) {
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<UpdatePasswordInput>()

  const oldPassword = watch('oldPassword')

  async function onSubmit(input: UpdatePasswordInput) {
    try {
      await N3k0Api.updatePassword(input)
      onPasswordSaved()
    } catch (error) {
      // TODO: add more types of error here
      if (error instanceof UnauthorizedError) {
        setErrorMsg(error.message)
      } else {
        alert(error)
      }
      console.error(error)
    }
  }

  return (
    <Modal show onHide={onDismiss}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
        <Form id="editPasswordForm" className="mb-3" onSubmit={handleSubmit(onSubmit)}>
          <TextInputField
            name="oldPassword"
            label="Old Password"
            type="password"
            register={register}
            registerOptions={{ required: 'Old password is required' }}
            error={errors.oldPassword}
          />

          <TextInputField
            name="newPassword"
            label="New Password"
            type="password"
            register={register}
            registerOptions={{
              required: 'New password is required',
              validate: (value) =>
                value !== oldPassword || 'New password cannot be the same as old password',
            }}
            error={errors.newPassword}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button form="editPasswordForm" type="submit" disabled={isSubmitting}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
