import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Post } from '../../models/post.model'
import * as N3k0Api from '../../service/n3k0_api'
import Container from '@mui/material/Container'
import { TagPagePostsView } from './TagPagePostsView'
import { TagPageTagsView } from './TagPageTagsView'
import { useLocation } from 'react-router-dom'

export const TagPage = () => {
  const [search, useSearch] = useSearchParams()
  const tag = search.get('tag')
 const location = useLocation()

  const [posts, setPosts] = useState<Post[]>([])

  const [tags, setTags] = useState<string[]>([])
  
// useEffect(() => {
//   setShowPostCard(false)
// }, [location])

  useEffect(() => {
    async function loadPostsByTag(tag: string) {
      try {
        const response = await N3k0Api.fetchPostByTag(tag)
        
        setPosts(response)
      } catch (error) {
        console.error(error)
        alert(error)
      }
    }

    async function loadAllTags() {
      try {
        const response = await N3k0Api.fetchAllTags()
        setTags(response)
      } catch (error) {
        console.error(error)
        alert(error)
      }
    }

    if (tag) {
      loadPostsByTag(tag)
    } else {
      // TODO: list all the tags
      loadAllTags()
    }
  }, [location])

  // TODO: display all posts with this tag, if there's no tag, then display all available tags (and maybe something else?)

  return (
    <Container maxWidth="lg">
      <h1>Tag: {tag ? tag : 'no tag'}</h1>
      {tag ? <TagPagePostsView posts={posts} /> : <TagPageTagsView tags={tags} />}
    </Container>
  )
}
