import { Divider, Link, Typography } from '@mui/material'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import styleUtils from '../../utils/util.module.css'
import styles from './ProfilePage.module.css'
import { AdminProfile } from '../../utils/admin'

export const ProfilePage = () => {
  return (
    <Container maxWidth="lg">
      <section className={styles.intro}>
        <h1>Me</h1>
        <Grid container spacing={5} alignItems="center">
          <Grid item xs={12} sm={4} justifyContent="center">
            <img
              className={`${styleUtils.width100}`}
              style={{ height: 'auto' }}
              src="/images/self.jpg"
              alt="August 2022"
            />
            <Typography variant="caption">August 2022</Typography>
          </Grid>

          <Grid item xs={12} sm={8}>
            <Typography variant="body1" gutterBottom>
              I am a very painful aerospace engineer unfortunately, but thanks to the perfect choice
              I have made, photography allows me to escape from real life. Most time I do landscape
              and urban photography but still, not good at it. I am very lucky to have a chance to
              begin my table tennis photography 'career', then I fell in love with that. Without
              doing this, I wouldn't have visited somes places before I pass away honestly, and
              these places have always rekindled my desire and perseverance of life. I hope I could
              be the one that captures their tenacity and fragility combine with a little personal
              thought rather than perfect techniques.
            </Typography>
          </Grid>
        </Grid>
      </section>

      {AdminProfile.map((line: string[]) => {
        return (
          <section className={`${styleUtils.flexCenter} ${styles.brief}`}>
            <Typography variant="body1">{line.join(' | ')}</Typography>
          </section>
        )
      })}

      <Divider variant="middle" />

      <section className={`${styleUtils.flexCenter} ${styles.contact}`}>
        <h1>CONTACT</h1>
        <Typography variant="body1">Email: arenacamera8@gmail.com</Typography>
        <Typography variant="body1">
          Instagram:&nbsp;
          <Link href="https://www.instagram.com/arenacamera/" underline="hover">
            @arenacamera
          </Link>
        </Typography>
      </section>
    </Container>
  )
}
