export interface Language {
  label: string
  code: string
}

export const LANGUAGES: Language[] = [
  { label: 'English', code: 'en-GB' },
  { label: '简体中文', code: 'zh-CN' },
]

// 使用reduce从LANGUAGES创建一个对象
const LANGUAGE_CODES = LANGUAGES.reduce((acc, lang) => {
  acc[lang.code] = lang.label
  return acc
}, {} as Record<string, string>)

// 这将创建一个联合类型，例如 "en-GB" | "zh-CN"
export type LanguageCode = keyof typeof LANGUAGE_CODES
