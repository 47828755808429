import { Alert, Button, TextField, Typography } from '@mui/material'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AppContext, IAppContext } from '../../context'
import { ConflictError } from '../../errors/http_errors'
import * as N3k0Api from '../../service/n3k0_api'
import styleUtils from '../../utils/util.module.css'

export const RegisterPage = () => {
  const { loggedInUser, setLoggedInUser } = useContext(AppContext) as IAppContext

  const navigate = useNavigate()

  const { t } = useTranslation()

  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const {
    register,
    handleSubmit,
    setValue,
    unregister,
    formState: { errors, isSubmitting },
  } = useForm<N3k0Api.SignUpCredentials>()

  async function onSubmit(credentials: N3k0Api.SignUpCredentials) {
    try {
      const newUser = await N3k0Api.signUp({ ...credentials, role: 'visitor' })
      // onSignUpSuccessful(newUser)
      setLoggedInUser(newUser)
      navigate('/')
    } catch (error) {
      if (error instanceof ConflictError) {
        setErrorMsg(error.message)
      } else {
        alert(error)
      }
      console.error(error)
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h4">Sign Up</Typography>

        <TextField
          label={t('Username')}
          variant="outlined"
          fullWidth
          margin="normal"
          {...register('username', { required: t('UsernameRequiredPrompt') })}
          error={Boolean(errors.username)}
          helperText={errors.username?.message}
        />

        <TextField
          label={t('Email')}
          variant="outlined"
          fullWidth
          margin="normal"
          {...register('email', { required: 'Email is required' })}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
        />

        <TextField
          label={t('Password')}
          variant="outlined"
          fullWidth
          margin="normal"
          type={'password'}
          {...register('password', { required: t('PasswordRequiredPrompt') })}
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
        />

        {errorMsg && <Alert severity="error">{errorMsg}</Alert>}

        <Button
          variant="contained"
          size="large"
          className={styleUtils.width100}
          type="submit"
          disabled={isSubmitting}
        >
          {t('SignUpBtn')}
        </Button>
      </form>
    </div>
  )
}
