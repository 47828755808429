import React from 'react'
import { Dialog, DialogTitle, DialogContent, Button, Box, Grid } from '@mui/material'

interface ModalProps {
  modalTitle?: string
  open: boolean
  onClose: () => void
  children?: React.ReactNode
  [key: string]: any
}

export const Modal = ({ modalTitle, open, onClose, children, ...props }: ModalProps) => {
  return (
    <Dialog open={open} onClose={onClose} {...props}>
      {modalTitle && <DialogTitle>{modalTitle}</DialogTitle>}
      <DialogContent
      // sx={{
      //   padding: 0, // Remove padding
      // }}
      >
        {children}
      </DialogContent>
    </Dialog>
  )
}
