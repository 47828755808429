import { Box, Button } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'
import { emptyUser } from '../../../constants/emptyUser'
import { AppContext, IAppContext } from '../../../context'
import * as N3k0Api from '../../../service/n3k0_api'
import styles from './NavBar.module.css'

interface NavBarLoggedInViewProps {
  isMobile?: boolean
  onLogoutSuccessful: () => void
}

export default function NavBarLoggedInView({
  isMobile,
  onLogoutSuccessful,
}: NavBarLoggedInViewProps) {
  const { loggedInUser, setLoggedInUser } = useContext(AppContext) as IAppContext

  const { t } = useTranslation()

  async function logout() {
    try {
      await N3k0Api.logout()
      onLogoutSuccessful()
    } catch (error) {
      console.error(error)
      alert(error)
    }
  }

  return (
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'}>
      <Button className={styles.NavBarButton} variant="text" component={RouterLink} to="/user">
        {t('CurrUser', { name: loggedInUser.username })}
      </Button>
      <Button
        variant="outlined"
        className={styles.NavBarButton}
        onClick={() => {
          logout()
          setLoggedInUser(emptyUser)
        }}
      >
        {t('LogoutBtn')}
      </Button>
    </Box>
  )
}
