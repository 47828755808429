import React, { useEffect, useState } from 'react'
import { Post } from '../../models/post.model'
import { ImageList, ImageListItem } from '@mui/material'
import PostCard from '../../components/layout/PostCard/PostCard'
import * as N3k0Api from '../../service/n3k0_api'
import { useLocation } from 'react-router-dom'

interface TagPagePostsViewProps {
  posts: Post[]
}

export const TagPagePostsView = ({ posts }: TagPagePostsViewProps) => {
  const location = useLocation()

  const [currPosts, setCurrPosts] = useState<Post[]>(posts)

  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [selectedPost, setSelectedPost] = useState<Post | null>(null)

  const [showPostCard, setShowPostCard] = useState(false)

  useEffect(() => {
    setCurrPosts(posts)
    if (posts.length > 0) {
      setSelectedPost(posts[0])
    }
  }, [posts])

  useEffect(() => {
    setShowPostCard(false)
  }, [location])

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % posts.length
    setCurrentIndex(nextIndex)
    setSelectedPost(posts[nextIndex])
  }

  const handlePrev = () => {
    const prevIndex = (currentIndex - 1 + posts.length) % posts.length
    setCurrentIndex(prevIndex)
    setSelectedPost(posts[prevIndex])
  }

  async function deletePost(post: Post) {
    try {
      await N3k0Api.deletePost(post._id)
      setCurrPosts(currPosts.filter((existingPosts) => existingPosts._id !== post._id))
    } catch (error) {
      console.error(error)
      alert(error)
    }
  }

  return (
    <>
      <ImageList cols={3} gap={8}>
        {/* TODO: Define a default sorting */}
        {currPosts.map((post) => (
          <ImageListItem key={post._id}>
            <img
              onClick={() => {
                setSelectedPost(post)
                setShowPostCard(true)
              }}
              src={`${post.imageURL}`}
              alt={post.title}
              title={post.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>

      <PostCard
        post={selectedPost}
        open={showPostCard}
        onPrevClicked={() => {
          handlePrev()
        }}
        onNextClicked={() => {
          handleNext()
        }}
        onClose={() => {
          setShowPostCard(false)
        }}
        onDeletePostClicked={(post) => {
          // update the post list state
          if (window.confirm(`Are you sure you want to delete ${post.title}?`)) {
            deletePost(post)
            // close the postcard
            setShowPostCard(false)
            setSelectedPost(null)
          }
        }}
        OnPostEdited={(updatedPost) => {
          setCurrPosts(
            // replace the post with the updated one
            currPosts.map((existingPost) =>
              existingPost._id === updatedPost._id ? updatedPost : existingPost
            )
          )
        }}
      />
    </>
  )
}
