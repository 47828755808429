import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { AppProvider } from './context'
import './index.css'
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
export const MainTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 2000,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(55,55,55)', // Or any color you want
        },
      },
    },
  },
})
root.render(
  <React.StrictMode>
    <AppProvider>
      <ThemeProvider theme={MainTheme}>
        <CssBaseline />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </AppProvider>
  </React.StrictMode>
)
