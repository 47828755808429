class GQFooter extends HTMLElement {
  private template: HTMLTemplateElement
  private styles: HTMLStyleElement
  private render: () => void

  constructor() {
    super()
    this.attachShadow({ mode: 'open' })

    this.template = document.createElement('template')
    this.styles = document.createElement('style')

    this.render = () => {
      this.template.innerHTML = `
      <footer id="footer">
        <p>some text</p>
      </footer>`

      // for some reason :host selector doesn't work, use class or id instead
      this.styles.innerHTML = `
      #footer{
        border: 1px solid;
      }`
    }
  }

  connectedCallback() {
    this.render()

    // 确保 shadowRoot 不是 null，然后添加样式和内容
    if (this.shadowRoot) {
      this.shadowRoot.appendChild(this.styles.cloneNode(true))
      this.shadowRoot.appendChild(this.template.content.cloneNode(true))
    }
  }
}

if (!customElements.get('gq-footer')) {
  customElements.define('gq-footer', GQFooter)
}

export default GQFooter
