
import { Container } from '@mui/material'
import PostPageLoggedInView from '../../components/PostPageViews/PostPageLoggedInView'
import styles from './PostPage.module.css'

export default function PostPage() {
  return (
    <Container maxWidth="lg">
      <PostPageLoggedInView />
    </Container>
  )
}
