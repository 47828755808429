import { Alert, Button, TextField, Typography } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AppContext, IAppContext } from '../../context'
import { InvalidRequestError } from '../../errors/http_errors'
import * as N3k0Api from '../../service/n3k0_api'
import { LoginCredentials } from '../../service/n3k0_api'
import styleUtils from '../../utils/util.module.css'

export const LoginPage = () => {
  const { loggedInUser, setLoggedInUser } = useContext(AppContext) as IAppContext

  const navigate = useNavigate()

  const { t } = useTranslation()

  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const {
    register,
    handleSubmit,
    setValue,
    unregister,
    formState: { errors, isSubmitting },
  } = useForm<LoginCredentials>()

  async function onSubmit(credentials: LoginCredentials) {
    try {
      const user = await N3k0Api.login(credentials)
      setLoggedInUser(user)
      navigate('/')
    } catch (error) {
      if (error instanceof InvalidRequestError) {
        setErrorMsg(error.message)
      } else {
        alert(error)
      }
      console.error(error)
    }
  }

  useEffect(() => {
    if (loggedInUser.username !== '') {
      navigate('/user')
    }
  }, [loggedInUser])

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h4">Login</Typography>

        <TextField
          label={`Email`}
          variant="outlined"
          fullWidth
          margin="normal"
          // required // This will cause browser's prompt overwrites custom error message
          {...register('email', { required: 'Email is required' })}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
        />

        <TextField
          label={`Password`}
          variant="outlined"
          fullWidth
          margin="normal"
          type={'password'}
          // required // This will cause browser's prompt overwrites custom error message
          {...register('password', { required: 'Password is required' })}
          error={Boolean(errors.password)}
          helperText={errors.password?.message}
        />

        {errorMsg && <Alert severity="error">{errorMsg}</Alert>}

        <Button
          variant="contained"
          size="large"
          className={styleUtils.width100}
          type="submit"
          disabled={isSubmitting}
        >
          {t('LoginBtn')}
        </Button>
      </form>
    </div>
  )
}
