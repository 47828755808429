import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from './NavBar.module.css'

interface NavBarLoggedOutViewProps {
  isMobile?: boolean
  onSignUpClicked: () => void
  // onLoginClicked: () => void
}

export default function NavBarLoggedOutView({
  isMobile,
  onSignUpClicked,
}: NavBarLoggedOutViewProps) {
  const { t } = useTranslation()
  return (
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} >
      {/* <Button onClick={onSignUpClicked}>{t('SignUpBtn')}</Button> */}
      {/* <Button variant="contained" onClick={onLoginClicked}>
        {t('LoginBtn')}
      </Button> */}
    </Box>
  )
}
